body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
}

#root {
    margin-bottom: 20px;
}

#plan-container {
    width: 300px;
    height: 200px;
    background-color: lightgray; /* Just to make it visible */
}

.plan-component {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.plan-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.plan-item label {
    margin-left: 10px;
}